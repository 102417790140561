var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.MedicationDetail && Object.keys(_vm.MedicationDetail).length)?_c('div',{staticClass:"medication-detail"},[(_vm.MedicationDetail.banners && _vm.MedicationDetail.banners.length)?_c('DrugsSlider',{attrs:{"banners":_vm.MedicationDetail.banners,"color":_vm.MedicationDetail.color}}):_vm._e(),_c('Breadcrumbs',{attrs:{"pages":[
        { name: 'Препараты АЗ', link: { name: 'Medications' } },
        { name: _vm.MedicationDetail.name } ]}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"medication-detail__row"},[_c('div',{staticClass:"medication-detail__list"},[_c('div',{staticClass:"material__tags"},[_vm._l((_vm.MedicationDetail.nosology),function(tag){return _c('div',{key:tag.id,staticClass:"material__tag",domProps:{"innerHTML":_vm._s(tag.title)}})}),_c('Favorite',{staticClass:"large ml-auto ml-xs-0",attrs:{"watchLater":_vm.MedicationDetail.watch_later,"favorite":_vm.MedicationDetail.favorite},on:{"watchLater":function($event){return _vm.watchLater(
                  _vm.MedicationDetail.watch_later ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )},"favorite":function($event){return _vm.favorite(
                  _vm.MedicationDetail.favorite ? 'DELETE' : 'POST',
                  _vm.MedicationDetail.slug,
                  null,
                  true
                )}}})],2),_c('div',{staticClass:"materials"},_vm._l((_vm.MedicationDetail.links),function(material){return _c('FileBlockNew',{key:material.id,attrs:{"material":material,"kind":material.file ? 'file' : 'link'},on:{"linkClick":function($event){return _vm.linkClick(material.name)}}})}),1),(_vm.MedicationDetail.about_blocks)?_c('div',_vm._l((_vm.MedicationDetail.about_blocks),function(item,ind){return _c('InfoDrop',{key:ind,attrs:{"item":item,"fixed":true,"color":_vm.MedicationDetail.color}})}),1):_vm._e(),_c('br'),_c('br'),(_vm.MedicationDetail.tabs)?_c('div',[_c('div',{attrs:{"id":"tabsTopAnchor"}}),_c('div',{staticClass:"medication-detail__tabs_compact"},_vm._l((_vm.MedicationDetail.tabs),function(tab,tInd){return _c('div',{key:tInd,staticClass:"medication-detail__tab",class:{
                  'medication-detail__tab_active': _vm.activeTab === tInd,
                },on:{"click":function($event){return _vm.changeTab(tInd)}}},[_c('div',{domProps:{"innerHTML":_vm._s(tab.colors)}}),_c('div',{staticClass:"medication-detail__tab-icon",domProps:{"innerHTML":_vm._s(tab.first_icon)}}),_c('div',{staticClass:"medication-detail__tab-text",domProps:{"innerHTML":_vm._s(tab.title)}}),_c('div',{staticClass:"medication-detail__tab-shape"},[_c('svg',{attrs:{"width":"52","height":"10","viewBox":"0 0 52 10","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M26 10C26 10 17 1 0 0L52 0C36 1 26 10 26 10Z","fill":"var(--bgColor)"}})])])])}),0),(_vm.MedicationDetail.tabs.every(function (el) { return el.second_icon; }))?_c('div',{staticClass:"medication-detail__tabs_full"},_vm._l((_vm.MedicationDetail.tabs),function(tab,tInd){return _c('div',{key:tInd,staticClass:"medication-detail__tab",class:{
                  'medication-detail__tab_active': _vm.activeTab === tInd,
                },on:{"click":function($event){return _vm.changeTab(tInd)}}},[_c('div',{staticClass:"medication-detail__tab-icon",domProps:{"innerHTML":_vm._s(tab.second_icon)}}),_c('div',{staticClass:"medication-detail__tab-text",domProps:{"innerHTML":_vm._s(tab.title)}})])}),0):_vm._e(),_vm._l((_vm.MedicationDetail.tabs),function(tab,tInd){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeTab === tInd),expression:"activeTab === tInd"}],key:tInd},_vm._l((tab.content),function(item,ind){return _c('div',{key:ind},[(
                    tab.materials && tab.materials.length && item.checkbox
                  )?_c('TabSlider',{attrs:{"tabs":_vm.materialsList(tab.materials)}}):_vm._e(),_c('InfoDrop',{attrs:{"item":item,"fixed":true,"color":_vm.MedicationDetail.color}})],1)}),0)})],2):_vm._e()]),_c('AsideLinks',{attrs:{"items":_vm.MedicationDetail.side_links}})],1)])],1):(_vm.MedicationDetail === 404)?_c('div',[_c('Choch',{staticClass:"inPage"})],1):(_vm.MedicationDetail === false)?_c('div',[_c('Error',{staticClass:"inPage"})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }